// import Icon from '@ant-design/icons-vue'
import * as antDesign from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import { App } from 'vue'
import svgIcon from '/@/components/svgIcon.vue'
import { createPinia } from 'pinia'
const pinia = createPinia()
export const antComponents = {
  install (app: App): void {
    app.use(pinia)
    app.use(antDesign)
    // app.component('Icon', Icon)
    app.component('svg-icon', svgIcon)
  }
}
