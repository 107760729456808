<template>
  <div class="demo-app" style="overflow: auto;">
    <router-view />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import { useMyStore } from './store'

export default defineComponent({
  name: 'App',
  components: { },

  setup () {
    const store = useMyStore()
    return {}
  }
})
</script>
<style lang="scss" scoped>
.demo-app {
  width: 100%;
  height: 100%;

  .map-wrapper {
    height: 100%;
    width: 100%;
  }
}
</style>

<style lang="scss">
#demo-app {
  width: 100%;
  height: 100%
}
</style>
