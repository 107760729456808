import App from './App.vue'
import router from './router'
import { antComponents } from './antd'
import { CommonComponents } from './use-common-components'
import 'virtual:svg-icons-register'
import store, { storeKey } from './store'
import { createInstance } from '/@/root'
import '/@/styles/index.scss'
import * as Sentry from '@sentry/vue'
import { CURRENT_CONFIG } from '/@/api/http/config'

const app = createInstance(App)

Sentry.init({
  app,
  dsn: 'https://7a82d2e4f7bfa38ca1e39a9c4e1f02e3@o4505267276087296.ingest.sentry.io/4506155369299968',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', CURRENT_CONFIG.baseURL],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: import.meta.env.VITE_TRACES_SAMPLE_RATE,
  replaysSessionSampleRate: import.meta.env.VITE_REPLAYS_SESSION_SAMPLE_RATE,
  replaysOnErrorSampleRate: import.meta.env.VITE_REPLAYS_ON_ERROR_SAMPLE_RATE,
})

app.use(store, storeKey)
app.use(router)
app.use(CommonComponents)
app.use(antComponents)
app.mount('#demo-app')
